export const initialUrl = "/my-profile"; // this url will open after login

export const AUTH_TOKEN: string = "app-auth-token";
export const EXHIBITOR_NAME: string = "exhibitor_name";
export const EXHIBITOR_LOGO: string = "exhibitor_logo";
export const EXHIBITOR_STAND_DESIGN: string = "stand_design";
export const Default_Language: string = "app-language";
export const AUTH_USERID:string = "auth_user_id";

export const statusValue = [
  { name: "Active", value: 1, color: "#34d399" },
  { name: "Inactive", value: 0, color: "#FDE047" },
];

export const videoTypes = [
  { label: "mp4", id: "0" },
  { label: "webm", id: "1" },
  { label: "ogg", id: "2" },
  { label: "mov", id: "3" },
  { label: "avi", id: "4" },
  { label: "wmv", id: "5" },
  { label: "flv", id: "6" },
];
export const standDesignItem = [
  { label: "1", id: 3, types: ["image", "video", "image"] },
  { label: "2", id: 4, types: ["image", "video", "image", "image"] },
  { label: "3", id: 2, types: ["image", "video"] },
  { label: "4", id: 1, types: ["image"] },
  { label: "5", id: 5, types: ["image", "video", "image", "video", "image"] },
  {
    label: "6",
    id: 6,
    types: ["image", "video", "image", "image", "video", "image"],
  },
];

export const standDesignFiled = [
  {
    value: "1",
    item: [
      {
        id: "",
        path: "",
        sort: 1,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 2,
        link: "",
        type: "video",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 3,
        link: "",
        type: "image",
        media_id: "",
      },
    ],
  },
  {
    value: "2",
    item: [
      {
        id: "",
        path: "",
        sort: 1,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 2,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 3,
        link: "",
        type: "",
        media_id: "",
      },
    ],
  },
  {
    value: "3",
    item: [
      {
        id: "",
        path: "",
        sort: 1,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 2,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 3,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 4,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 5,
        link: "",
        type: "",
        media_id: "",
      },
    ],
  },
  {
    value: "4",
    item: [
      {
        id: "",
        path: "",
        sort: 1,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 2,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 3,
        link: "",
        type: "",
        media_id: "",
      },
    ],
  },
  {
    value: "5",
    item: [
      {
        id: "",
        path: "",
        sort: 1,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 2,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 3,
        link: "",
        type: "",
        media_id: "",
      },
      {
        id: "",
        path: "",
        sort: 4,
        link: "",
        type: "",
        media_id: "",
      },
    ],
  }
];

export const imageTypes = [
  { label: "jpeg", id: "0" },
  { label: "bmp", id: "1" },
  { label: "svg", id: "2" },
  { label: "png", id: "3" },
  { label: "jpg", id: "4" },
  { label: "gif", id: "5" },
];

export const maxImageSize = [
  { label: "equal or less than 1 MB", id: "0", value: "1" },
  { label: "equal or less than 2 MB", id: "1", value: "2" },
  { label: "equal or less than 4 MB", id: "2", value: "4" },
  { label: "equal or less than 8 MB", id: "3", value: "8" },
  { label: "equal or less than 16 MB", id: "4", value: "16" },
  { label: "Up to 16 MB", id: "5", value: "16" },
];

export const maxVideoSize = [
  { label: "equal or less than 16 MB", id: "0", value: "16" },
  { label: "equal or less than 32 MB", id: "1", value: "32" },
  { label: "equal or less than 64 MB", id: "2", value: "64" },
  { label: "equal or less than 128 MB", id: "3", value: "128" },
  { label: "Up to 128 MB", id: "4", value: "128" },
];

export const standDesign = [
  { label: "1", id: "1" },
  { label: "2", id: "2" },
  { label: "3", id: "3" },
  { label: "4", id: "4" },
  { label: "5", id: "5" },
];
