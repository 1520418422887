import {
  FooterType,
  LayoutType,
  MenuStyle,
  NavStyle,
  ThemeDirection,
  ThemeMode,
  ThemeStyle,
} from "../../../shared/constants/AppEnums";

export const DarkSidebar = {
  sidebarBgColor: "#FFFFFF",
  sidebarTextColor: "#FFF",
  sidebarHeaderColor: "#FFFFFF",
  sidebarMenuSelectedBgColor: "#002F6F",
  sidebarMenuSelectedTextColor: "#A78349",
  mode: ThemeMode.DARK,
};
export const LightSidebar = {
  sidebarBgColor: "#fff",
  sidebarTextColor: "rgba(0, 0, 0, 0.60)",
  sidebarHeaderColor: "#fff",
  sidebarMenuSelectedBgColor: "red",
  sidebarMenuSelectedTextColor: "rgba(0, 0, 0, 0.87)",
  mode: ThemeMode.LIGHT,
};
const defaultConfig = {
  sidebar: {
    borderColor: "#757575",
    menuStyle: MenuStyle.DEFAULT,
    isSidebarBgImage: false,
    sidebarBgImage: 1,
    colorSet: DarkSidebar,
    sidebarMenuSelectedColor: DarkSidebar.sidebarMenuSelectedBgColor,
  },

  locale: {
    code: "en",
    name: "English",
    icon: "uk",
  },
  themeStyle: ThemeStyle.MODERN,
  direction: ThemeDirection.LTR,
  themeMode: ThemeMode.SEMI_DARK,
  footerType: FooterType.FLUID,
  navStyle: NavStyle.MINI_SIDEBAR_TOGGLE,
  layoutType: LayoutType.FULL_WIDTH,
  footer: true,
  rtlLocale: ["ar"],
};
export default defaultConfig;
