import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSidebarContext } from '../../../utility/AppContextProvider/SidebarContextProvider';
import AppScrollbar from '../../AppScrollbar';
import AppLogo from '../components/AppLogo';
import AppVerticalMenu from '../components/AppVerticalNav';
import MainSidebar from '../components/MainSidebar';
import './index.style.less';

const AppSidebar = ({ isCollapsed }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <MainSidebar
      className={clsx('mini-sidebar-toggle')}
      collapsible
      breakpoint='xl'
      collapsedWidth='0'
      collapsed={isCollapsed}
      >
      <div
        style={{
          backgroundColor: sidebarColorSet.sidebarHeaderColor,
          color: sidebarColorSet.sidebarTextColor
        }}
        className={clsx('cr-user-info cr-user-info-hasColor')}>
        <div className='app-title-logo'><AppLogo /> </div>
      </div>
      <AppScrollbar className='app-mini-sidebar-scrollbar' scrollToTop={false}>
        <AppVerticalMenu />
      </AppScrollbar>
      {/* <Logout /> */}
    </MainSidebar>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
};
