/* eslint-disable react/prop-types */
//react
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { notifier } from "./shared/functions/notifier";
import { openNotification } from "./shared/functions/notification/openNotification";

const NotificationAlert = ({ notification }) => {
  //navigate
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(true);

  //notifications data
  const { title = "", body = "" } = notification;
  const fullNotif = title + body;

  const goToNotifications = () => {
    navigate(`/notifications`);
    setShowNotification(false);
  };
  if (!showNotification) {
    return null;
  }
  function Display() {
    return (
      <div>
        {notification && openNotification(title, body, goToNotifications)}
      </div>
    );
  }

  return <Display />;
};

export default NotificationAlert;
