import React from "react";

import PropTypes, { string } from "prop-types";
import { List, Avatar, Row, Col } from "antd";
import "./NotificationItem.less";
import { NotificationDTO } from "../../../shared/services/notifications/dtos";
import { MdNotificationsActive } from "react-icons/md";
import MainUtils from "../../../shared/utils/main";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { notificationsService } from "../../../shared/services/notifications";

interface ItemProps {
  item?: NotificationDTO;
}

const NotificationItem = (props: ItemProps) => {
  const { item } = props;
  const navigate = useNavigate();

  const handleClickNotification = async () => {
    if (MainUtils.isEmptyValue(item?.link)) {
      navigate("/notifications");
    } else {
      const data = await notificationsService.isVisitLink({
        notification_user_id: item?.notification_user_id,
      });
      window.open(item?.link, "_blank");
    }
  };

  return (
    <List.Item
      className="notify-listItem item-hover"
      onClick={handleClickNotification}
    >
      <List.Item.Meta
        // avatar={<MdNotificationsActive />}
        title={
          <Row justify="space-between">
            <Col
              style={{ fontSize:"12px", textAlign: "right", color: "#6B728E", marginBottom: "5px" }}
              span={24}
            >
              {moment(item?.created_at).calendar()}{" "}
            </Col>
            <Col span={24}>
              {!item?.is_visit_link ? (
                <>
                  <span
                    style={{ marginRight: "10px" }}
                    className="point"
                  ></span>
                  <span style={{fontSize: "15px"}}>{item?.title}</span>
                </>
              ) : (
                <span style={{fontSize: "15px"}}>{item?.title}</span>
              )}
            </Col>
          </Row>
        }
        description={item?.body}
      />
    </List.Item>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
