import { useContext, useEffect, useState } from "react";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { SocketContext } from "../../../App";
import IntlMessages from "../../utility/IntlMessages";
import "./index.style.less";

const AppChat = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current path
  const socket = useContext(SocketContext);

  const [hasNewMessage, setHasNewMessage] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/chat") { // Only listen for messages if not currently on /chat
      socket?.on("getMessage", (message: any) => {
        if (message && message?.message?.content) {
          setHasNewMessage(true);
        }
      });
    }

    return () => {
      socket?.off("getMessage"); // Clean up the event listener when the component unmounts or the location changes
    };
  }, [socket, location.pathname]); // Add location.pathname as a dependency

  return (
    <>
      <a className="notify-link" onClick={() => {
        navigate("/chat");
        setHasNewMessage(false); // Reset notification dot when navigating to chat
      }}>
        <span className="notify-icon">
          <BiSolidMessageRoundedDetail style={{ color: '#2c3c6c' }} />

          {hasNewMessage && <span
            style={{
              position: 'absolute',
              top: '1px',
              right: '3px',
              width: '9px',
              height: '9px',
              backgroundColor: 'red',
              borderRadius: '50%',
              zIndex: 1,
            }}
          ></span>}
        </span>
        <span className="notify-text">
          <IntlMessages id="common.messages" />
        </span>
      </a>
    </>
  );
};

export default AppChat;