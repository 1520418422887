import { Box, Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import Footer from './Footer';
import Header from './Header';
import Message from './Message';

const Conversation = ({ messages, selectedUserName, sendMessage, onlineUsers, selectedUserId }: { messages: any[], selectedUserName: string | null, sendMessage: (content: string, recipientId: number | any) => void, onlineUsers: any[], selectedUserId: number | any }) => {
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Stack height={"100%"} maxHeight={"100vh"} width={"auto"}>
            {/* Chat Header */}
            <Header userName={selectedUserName} onlineUsers={onlineUsers} selectedUserId={selectedUserId} />
            {/* Msg */}
            <Box width={"100%"} sx={{ flexGrow: 1, height: "100%", overflowY: "scroll", borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
                <Message messages={messages} />
                <div ref={messagesEndRef} />
            </Box>
            {/* Chat Footer */}
            <Footer sendMessage={sendMessage} selectedUserId={selectedUserId} />
        </Stack >
    )
}

export default Conversation;