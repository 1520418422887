import { Box, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmojiPicker from 'emoji-picker-react';
import { PaperPlaneTilt, Smiley } from 'phosphor-react';
import React, { useEffect, useState } from 'react';

type FooterProps = {
    sendMessage: (content: string, selectedUserId: number) => void;
    selectedUserId: number | null;
};

const StyledInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-input": {
        paddingTop: "12px !important",
        paddingBottom: "12px !important",
    },
}));

const Footer: React.FC<FooterProps> = ({ sendMessage, selectedUserId }: any) => {
    const [messageContent, setMessageContent] = useState<string>("");
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

    useEffect(()=>{
        setShowEmojiPicker(false)
    },[selectedUserId]);

    const handleSendMessage = () => {
        if (messageContent.trim() !== "") {
            if (selectedUserId !== null) {
                sendMessage(messageContent, selectedUserId);
            }
            setMessageContent("");
        }
    };

    const handleAddEmoji = (emoji: any) => {
        setMessageContent((prevContent) => prevContent + emoji?.emoji);
    };

    return (
        <Box
            p={2}
            sx={{
                width: "100%",
                backgroundColor: "#F8FAFF",
                boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px"
            }}
        >
            {showEmojiPicker && (
                <Box position="absolute" bottom="60px" right="20px" zIndex="modal">
                    <Stack direction="row" alignItems="center">
                        <IconButton
                            sx={{
                                bgcolor: "#fff",
                                borderRadius: 0,
                                border: "1px solid #ddd",
                                borderRight: "none",
                                fontSize: "14px",
                                "&:hover": {
                                    bgcolor: "#fff"
                                }
                            }}
                            onClick={() => setShowEmojiPicker(false)}
                        >
                            ❌
                        </IconButton>
                        <EmojiPicker onEmojiClick={handleAddEmoji} />
                    </Stack>
                </Box>
            )}
            <Stack direction="row" alignItems="center" spacing={3}>
                <Stack sx={{ width: "100%" }}>
                    <StyledInput
                        fullWidth
                        placeholder='Write a message...'
                        variant='filled'
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                        <Smiley />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <Box sx={{
                    height: 48,
                    width: 48,
                    backgroundColor: '#3c4d82',
                    borderRadius: 1.5
                }}>
                    <Stack
                        sx={{
                            height: "100%",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <IconButton onClick={handleSendMessage}>
                            <PaperPlaneTilt color="#fff" />
                        </IconButton>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default Footer;
