import { Avatar, Box, Stack, Typography } from '@mui/material';
import StyledBadge from '../../settings/StyledBadge';

const Header = ({ userName, selectedUserId, onlineUsers }: { userName: string | null, selectedUserId: number | any, onlineUsers: any[] }) => {
    const isOnline = onlineUsers.includes(selectedUserId);

    return (
        <Box
            p={2}
            sx={{
                // height: 100, 
                width: "100%", backgroundColor: "#2c3c6c",
                boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                borderTopRightRadius: "8px", borderBottomRightRadius: "8px"
            }}>
            <Stack alignItems={"center"} direction="row"
                justifyContent="space-between" sx={{ width: "100%", height: "100%" }}>
                <Stack direction={"row"} columnGap={2}>
                    <Box>
                        {isOnline ? (
                            <StyledBadge
                                overlap='circular'
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                                variant='dot'
                            >
                                <Avatar />
                            </StyledBadge>
                        ) : (
                            <Avatar />
                        )}
                    </Box>
                    <Stack direction="column" rowGap={0.2}>
                        <Typography variant="subtitle2" sx={{
                            color: "#efeeef"
                        }}>
                            {userName}
                        </Typography>
                        <Typography variant="caption" sx={{
                            color: isOnline ? "#44b700" : "#bbb"
                        }}>
                            {isOnline ? 'Online' : 'Offline'}
                        </Typography>
                    </Stack>
                </Stack>
                {/* <Stack direction="row" alignItems={"center"} spacing={3}>
                    <IconButton sx={{ color: '#efeeef' }}>
                        <MagnifyingGlass />
                    </IconButton>
                </Stack> */}
            </Stack>
        </Box>
    );
}

export default Header;
