import { Menu } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";
import defaultConfig from "../../../../utility/AppContextProvider/defaultConfig";
import { getRouteMenus } from "../../../../utility/VerticalMenuUtils";
import "./index.style.less";

const AppVerticalNav = () => {
  const { sidebarColorSet }: any = useSidebarContext();
  const { pathname } = useLocation();
  const selectedKeys = pathname.substr(1).split("/");
  const defaultOpenKeys = selectedKeys[0];
  const [openKeys, setOpenKeys] = useState([defaultOpenKeys]);

  useEffect(() => {
    setOpenKeys([selectedKeys[selectedKeys.length - 2]]);
  }, []);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      theme={sidebarColorSet.mode}
      mode="inline"
      className={clsx("app-main-sidebar-menu ", {
        "bg-color-menu":
          sidebarColorSet.sidebarBgColor !==
          defaultConfig.sidebar.colorSet.sidebarBgColor,
      })}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[selectedKeys[selectedKeys.length - 1]]}
    >
      {getRouteMenus()}
    </Menu>
  );
};

export default AppVerticalNav;
