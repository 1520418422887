import { Button, notification } from "antd";
import React from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
export const openNotification = (
  title: string,
  body: string,
  goToNotifications: any
) => {
  notification["info"]({
    message: <span style={{ fontWeight: "bold" }}>{title}</span>,
    description: body,
    onClick: goToNotifications,
    icon: <IoIosNotificationsOutline />,
    className: "notification-message",
  });
};
