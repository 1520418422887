import { Box, Stack, Typography } from "@mui/material";
import { TimeLine } from "./MsgTypes";

const formatTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const Message = ({ messages }: { messages: any[] }) => {
    return (
        <Box p={3}>
            <Stack spacing={3}>
                {messages.map((el, index) => {
                    if (el?.messages) {
                        return (
                            <Stack key={index} spacing={1}>
                                <TimeLine el={{ text: el.date }} />
                                {el?.messages.map((msg: any, idx: number) => (
                                    <Stack key={idx} direction="column" alignItems={msg?.is_sender ? 'flex-end' : 'flex-start'}>
                                        <Box sx={{ backgroundColor: msg?.is_sender ? '#e8ebf0' : '#3c4d82', borderRadius: 3, padding: 1.3, maxWidth: '70%', color: msg?.is_sender ? '#000' : '#fff' }}>
                                            <Typography variant="body2">{msg?.message}</Typography>
                                        </Box>
                                        <Typography variant="caption" sx={{ color: '#888' }}>
                                            {formatTime(msg?.date)}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        );
                    }
                    return <></>;
                })}
            </Stack>
        </Box>
    );
};

export default Message;
