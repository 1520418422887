import ApiService from "../../utils/base-api/api-service";
import {
  ResponseNotificationsDTO,
  ResponseNotificationsFirstTenDTO,
  ResponseUnreadCountDTO,
} from "./dtos";

class NotificationsService extends ApiService {
  constructor() {
    super({
      baseURL: `${process.env.REACT_APP_PUBLIC_BASE_API}/`,
    });
  }
  getAll(params: string): Promise<ResponseNotificationsDTO> {
    return this.get(`notifications${params}`);
  }
  getFirstTen(): Promise<ResponseNotificationsFirstTenDTO> {
    return this.get(`notifications/ten`);
  }

  unreadCount(): Promise<ResponseUnreadCountDTO> {
    return this.get(`notifications/unread_count`);
  }

  isRead(): Promise<any> {
    return this.patch("notifications/read");
  }
  isVisitLink(data: any): Promise<any> {
    return this.patch("notifications/visit_link", data);
  }
}

export const notificationsService = new NotificationsService();
