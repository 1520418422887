import { Layout } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { FooterType } from "../../../../shared/constants/AppEnums";
import { AppContentView } from "../../../index";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import AppScrollbar from "../../AppScrollbar";
import AppFooter from "../components/AppFooter";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import "./index.style.less";

const MiniSidebarToggle = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const { footer, footerType }: any = useLayoutContext();

  const onToggleSidebar = () => {
    setCollapsed(!isCollapsed);
  };
  return (
    <Layout
      className={clsx("app-layout-mini-sidebar", {
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}
    >
      <AppSidebar isCollapsed={isCollapsed} />
      <Layout className="app-layout-mini-sidebar-main">
        <AppHeader
          isCollapsed={isCollapsed}
          onToggleSidebar={onToggleSidebar}
        />
        <AppScrollbar className="main-mini-sidebar-scrollbar">
          <AppContentView />
          <AppFooter />
        </AppScrollbar>
      </Layout>
    </Layout>
  );
};

export default MiniSidebarToggle;
