import { Layout } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useUrlSearchParams } from "use-url-search-params";
import { LayoutType } from "../../../shared/constants/AppEnums";
import { AppContentView } from "../../index";
import {
  useLayoutActionsContext,
  useLayoutContext,
} from "../../utility/AppContextProvider/LayoutContextProvider";
import { useSidebarActionsContext } from "../../utility/AppContextProvider/SidebarContextProvider";
import { useAuthUser } from "../../utility/AuthHooks";
import MiniSidebarToggle from "./MiniSidebarToggle";
import "./layout.style.less";

const AppLayout = () => {
  const { isAuthenticated } = useAuthUser();
  const { layoutType }: any = useLayoutContext();
  const { updateNavStyle }: any = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage }: any =
    useSidebarActionsContext();
  const [params] = useUrlSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (layoutType === LayoutType.BOXED) {
      document.body.classList.add("boxedLayout");
      document.body.classList.remove("framedLayout");
    } else if (layoutType === LayoutType.FRAMED) {
      document.body.classList.remove("boxedLayout");
      document.body.classList.add("framedLayout");
    } else {
      document.body.classList.remove("boxedLayout");
      document.body.classList.remove("framedLayout");
    }
  }, [layoutType]);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, []);

  // useEffect(() => {
  //   const pageTitle = document.title.split(" - ")[0];
  //   const newPath = location.pathname.split("/")[1];
  //   const newTitle = `${pageTitle} - ${newPath}`;

  //   document.title = newTitle;
  // }, [location]);
  return (
    <React.Fragment>
      <Helmet>
        <title>{document.title}</title>
      </Helmet>
      {isAuthenticated ? (
        <MiniSidebarToggle />
      ) : (
        <Layout className="auth">
          {/* <AppScrollbar className="main-auth-scrollbar"> */}
          <AppContentView />
          {/* </AppScrollbar> */}
        </Layout>
      )}
    </React.Fragment>
  );
};

export default React.memo(AppLayout);
