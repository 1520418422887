import { ReactElement, createContext, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import {
  AUTH_TOKEN,
  AUTH_USERID,
  EXHIBITOR_LOGO,
  EXHIBITOR_NAME,
  EXHIBITOR_STAND_DESIGN,
} from "../../../shared/constants/AppConst";
import { notifier } from "../../../shared/functions/notifier";
import { authService } from "../../../shared/services/auth";
import { ILoginDto } from "../../../shared/services/auth/dtos/login-dto";
import MainUtils from "../../../shared/utils/main";
import { AuthContextProps } from "./types";
export const AuthContext = createContext<AuthContextProps>({});

const AuthContextProvider = ({ children }: { children: ReactElement<any, any> }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
  const [responseError, setError] = useState<any>("");
  const [cookies, setCookies, removeCookies] = useCookies([AUTH_TOKEN]);

  const [cookiesEmail] = useCookies(["email"]);
  // const [userId, setUserId] = useState();
  const [userId, setUserId] = useState<undefined | number>(undefined);

  const logout = async () => {
    setLoading(true);
    await authService.logout().then(async (response: any) => { });
    removeCookies(AUTH_TOKEN);
    removeCookies(EXHIBITOR_NAME);
    setVisibleLogout(false);
    setLoading(false);
  };

  const login = async (values: any) => {
    setLoading(true);

    let request: ILoginDto = {
      email: values?.email,
      password: values?.password,
      fcm_token: values.fcm_token,
    };
    await authService
      .login(request)
      .then((response: any) => {
        setUserId(response?.data?.id)
        if (!MainUtils.isEmptyValue(response?.errors)) {
          response?.errors?.map((error: string) => {
            notifier(error, "error");
          });
        }
        if (response?.data?.token && response?.data?.id) {
          setCookies(AUTH_USERID, `${response?.data?.id}`, {
            path: "/",
            sameSite: true,
          });
          setCookies(AUTH_TOKEN, `${response?.data?.token}`, {
            path: "/",
            sameSite: true,
          });
          setCookies(EXHIBITOR_NAME, `${response?.data?.name}`, {
            path: "/",
            sameSite: true,
          });
          setCookies(EXHIBITOR_LOGO, `${response?.data?.logo}`, {
            path: "/",
            sameSite: true,
          });
          setCookies(
            EXHIBITOR_STAND_DESIGN,
            `${response?.data?.stand_design}`,
            {
              path: "/",
              sameSite: true,
            }
          );
        }
      })
      .catch(({ response }) => {
        const errorMessage: string =
          response?.data?.errors?.[
          Object.keys(response?.data?.errors)[0]
          ]?.[0] || "Something went wrong, try again later!";
        notifier(errorMessage, "error");
      });
    setLoading(false);
  };

  const recoverPassword = async (values: any) => {
    setLoading(true);
    await authService
      .resetPassword({ ...values, email: cookiesEmail["email"] })
      .then((response: any) => {
        if (response?.error) {
          setError(response?.error?.message);
        }
        if (response?.data) {
          setError("");
          window.location.href = "/sign-in";
        }
      });
    setLoading(false);
  };

  const resendCode = async (values: any) => {
    setLoading(true);
    await authService.resendCode({ ...values }).then((response: any) => {
      if (response?.error) {
        setError(response?.error?.message);
      }

      if (response) {
        setError("");
        window.location.replace("/confirm-code");
      }
    });
    setLoading(false);
  };

  const confirmCode = async (values: any) => {
    setLoading(true);
    await authService.confirmCode({ ...values }).then((response: any) => {
      if (response?.error) {
        setError(response?.error?.message);
      }
      setCookies("email", `${values?.email}`, {
        path: "/",
        sameSite: true,
      });
      if (response?.data) {
        setError("");
        <Navigate to={"forget-password"} replace />;
      }
    });
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        responseError: responseError,
        loading: loading,
        visibleLogout: visibleLogout,
        setVisibleLogout: setVisibleLogout,
        logout: logout,
        login: login,
        recoverPassword: recoverPassword,
        isAuthenticated: authenticated,
        setAuthenticated: setAuthenticated,
        confirmCode: confirmCode,
        userId: userId,
        setUserId: setUserId,
        resendCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
