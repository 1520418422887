import { measureTextWidth } from "@ant-design/plots";
import { DefaultOptionType } from "antd/lib/select";

class utils {
  isEmptyValue(value: any) {
    return (
      typeof value === "undefined" ||
      typeof value === "undefined" ||
      typeof value === undefined ||
      value === null ||
      value === "" ||
      value === "" ||
      value.length === 0 ||
      value === "undefined"
    );
  }

  /*check only Object*/
  isEmptyObject(obj: any) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  getTableColumns(obj: any) {
    if (!this.isEmptyObject(obj))
      return Object.keys(obj).map((key: any) => {
        return {
          title: key,
          dataIndex: key,
          key: key,
        };
      });
  }

  renderStatistic(containerWidth: any, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  selectorSearchInValues(input: string, option: DefaultOptionType | undefined) {
    return (
      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  cloneObject(obj: any) {
    if (!this.isEmptyObject(obj)) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      return null;
    }
  }
  filterOptions = (input: any, option: any, label?: any) => {
    return label
      ? (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      : option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
  };

  cloneArray(array: Array<any>) {
    if (!this.isEmptyValue(array)) {
      return JSON.parse(JSON.stringify(array));
    } else {
      return null;
    }
  }
}

const MainUtils = new utils();
export default MainUtils;
