import React, { ReactNode } from 'react';
import {AppLoader} from '../index';
import PropTypes from 'prop-types';
import {useAuthUser} from './AuthHooks';

const AuthRoutes = ({children}: { children: ReactNode}) => {
  const {isLoading}: any = useAuthUser();
  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
