import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { AUTH_TOKEN } from "../../shared/constants/AppConst";

export const useAuthUser = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const [cookies] = useCookies();

  useEffect(() => {
    if (cookies[AUTH_TOKEN]) setAuthenticated(true);
    else setAuthenticated(false);
  }, [cookies[AUTH_TOKEN]]);

  return {
    isLoading: false,
    isAuthenticated: authenticated,
    setAuthenticated: setAuthenticated,
    user: {},
  };
};
