import { BiSolidVideos } from "react-icons/bi";
import { FaRegFilePdf } from "react-icons/fa";
import { IoIosDesktop, IoMdImages } from "react-icons/io";
import { LiaSchoolSolid } from "react-icons/lia";
import { LuLink } from "react-icons/lu";
const routesConfig = [
  {
    id: "stand-design",
    title: "standDesign",
    messageId: "standDesign",
    type: "item",
    icon: <IoIosDesktop />,
    path: "/stand-design",
  },
  {
    id: "images",
    title: "images",
    messageId: "images",
    type: "item",
    icon: <IoMdImages />,
    path: "/images",
  },
  {
    id: "videos",
    title: "videos",
    messageId: "videos",
    type: "item",
    icon: <BiSolidVideos />,
    path: "/videos",
  },

  {
    id: "programs",
    title: "programs",
    messageId: "programs",
    type: "item",
    icon: <LiaSchoolSolid />,
    path: "/programs",
  },
  {
    id: "links",
    title: "links",
    messageId: "links",
    type: "item",
    icon: <LuLink />,
    path: "/links",
  },
  {
    id: "files",
    title: "files",
    messageId: "files",
    type: "item",
    icon: <FaRegFilePdf />,
    path: "/files",
  },
  // {
  //   id: "notification",
  //   title: "notification",
  //   messageId: "notification",
  //   type: "item",
  //   icon: <MdOutlineNotificationsActive />,
  //   path: "/notifications",
  // },
];

export default routesConfig;
