import "firebase/compat/messaging";
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyBPhwmRCAhKjIzS_eNI7CA26WabazldWbM",
  authDomain: "virtual-fairs-651b3.firebaseapp.com",
  projectId: "virtual-fairs-651b3",
  storageBucket: "virtual-fairs-651b3.appspot.com",
  messagingSenderId: "219265304670",
  appId: "1:219265304670:web:3490b1000f559bf71a8ccf",
  measurementId: "G-87NM92JVFQ"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

const publicKey =
  "BMfGnv7VGVtpHOFt80IhSxYSgaI4633x0ZUXROZYJXhFlY-j1ZGUey0JHCmD1J9cmfUHfoNE5IhkYgHEIlNv7H4";

export const getFirebaseToken = async () => {
  let currentToken = null;
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
