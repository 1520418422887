import React from "react";

const SignIn = React.lazy(() => import("./sign-in"));
const ForgotPassword = React.lazy(() => import("./forgot-password"));
const ConfirmCode = React.lazy(() => import("./confirm-code"));
const ResendCode = React.lazy(() => import("./resend-code"));

export const authRouteConfig = [
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
  {
    path: "/confirm-code/:email",
    element: <ConfirmCode />,
  },
];
