import { Dropdown, List } from "antd";
import clsx from "clsx";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../pages/auth/context";
import GlobalPopup from "../../../../../shared/components/global-popup";
import {
  EXHIBITOR_NAME
} from "../../../../../shared/constants/AppConst";
import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";
import "./index.style.less";

const UserInfo = () => {
  const { themeMode }: any = useThemeContext();
  const { logout }: any = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [cookies] = useCookies([EXHIBITOR_NAME]);
  
  const menu = (
    <List className="dropdown-list">
      <List.Item onClick={() => navigate("/my-profile")}>My Profile</List.Item>
      <List.Item onClick={() => setVisible(true)}>Logout</List.Item>
    </List>
  );

  return (
    <>
      <div
        className={clsx("cr-user-info", {
          light: themeMode === "light",
        })}
      >
        <Dropdown
          className="user-profile-dropdown"
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
          overlayStyle={{
            zIndex: 1052,
            minWidth: 150,
          }}
        >
          <a className="cr-user-info-inner ant-dropdown-link">
            {/* <Avatar
              className="cr-user-info-avatar"
              src={
                <img
                  src={`${process.env.REACT_APP_PUBLIC_BASE_IMAGE}/${cookies[EXHIBITOR_LOGO]}`}
                />
              }
              size={40}
            ></Avatar> */}
            <span className="cr-user-info-content">
              <span className="cr-user-name-info">
                <h3
                  className={clsx("cr-user-name", {
                    light: themeMode === "light",
                  })}
                >
                  {cookies[EXHIBITOR_NAME]}
                
                </h3>
                <span className="cr-user-arrow">
                  <FaChevronDown />
                </span>
              </span>
            </span>
          </a>
        </Dropdown>
      </div>
      {
        visible && (
          <GlobalPopup
          title={"Are you sure you want to log out?"}
          visible={visible}
          setVisible={setVisible}
          handleSave={logout}
        />
        )
      }
    </>
  );
};

export default UserInfo;
