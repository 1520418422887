import { Col, Layout, Row } from "antd";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import "./AppFooter.style.less";

const { Footer } = Layout;

const AppFooter = () => {
  const { footer }: any = useLayoutContext();
  let year: any = new Date().getFullYear();

  if (footer) {
    return (
      <Footer className="app-main-footer">
        <Row className={"row"} justify={"center"} align={"middle"}>
          <Col>
            {"© " + year + " "}
            <a
              href="http://ridgeco.tech/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ridge Tech
            </a>
          </Col>
        </Row>
      </Footer>
    );
  }
  return null;
};

export default AppFooter;
