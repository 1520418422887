import { Box, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { OnlineUsersContext, SocketContext } from "../../App";
import Conversation from "../../components/Conversation";
import { AUTH_USERID } from "../../shared/constants/AppConst";
import { UsersChatListDTO } from "../../shared/services/chats/dtos";
import Chats from "./Chats";

const GeneralApp = () => {
    
    const socket = useContext(SocketContext);
    const appOnlineUsers = useContext(OnlineUsersContext);

    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectedUserName, setSelectedUserName] = useState<string | null>(null);
    const [messages, setMessages] = useState<any[]>([]);
    const [onlineUsers, setOnlineUsers] = useState<number[]>(appOnlineUsers);
    const [filteredUsers, setFilteredUsers] = useState<UsersChatListDTO[] | any[]>([]);
    const [users, setUsers] = useState<UsersChatListDTO[] | any[]>([]);
    const [cookies] = useCookies([AUTH_USERID]);
    const userid = cookies[AUTH_USERID]
    
    useEffect(() => {
        // This is where you emit 'addNewAccount' to signal this user is online
        socket?.emit('addNewAccount', {
            "id": Number(userid),
            "type": 1,
        });

        const updateOnlineUsers = (userIds: number[]) => {
            setOnlineUsers(userIds);
        };

        socket?.on("onlineUsers", updateOnlineUsers);

        return () => {
            socket?.off("onlineUsers", updateOnlineUsers);
        };

    }, [socket]);

    const sendMessage = (content: string, recipientId: number) => {
        const newMessageToSend: any = {
            content: content,
            senderType: 1,
            senderId: Number(userid),
            recipientId: recipientId
        };

        socket?.emit("sendMessage", newMessageToSend);

        const newLocalMessage: any = {
            message: content,
            date: new Date().toISOString(),
            is_sender: true
        };

        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const today = new Date().toISOString().split('T')[0];

            const existingConversation = updatedMessages.find(
                (conversation) => conversation.date === today
            );

            if (existingConversation) {
                existingConversation.messages.push(newLocalMessage);
            } else {
                updatedMessages.push({
                    date: today,
                    messages: [newLocalMessage]
                });
            }
            return updatedMessages;
        });

        setUsers((currentUsers: any) => {
            return currentUsers.map((user: any) => {
               if (user.account_id === recipientId) {
            return {
              ...user,
              last_message: content,
              last_message_date: new Date().toISOString(),
            };
          }
          return user;
            });
          });
          setFilteredUsers((currentFilteredUsers: any) => {
            return currentFilteredUsers.map((user: any) => {
               if (user.account_id === recipientId) {
            return {
              ...user,
              last_message: content,
              last_message_date: new Date().toISOString(),
            };
          }
          return user;
            });
          });
    };
    
    useEffect(() => {
        
        const getMessage = (data: any) => {
            
            let newMessage: any;        
            
            if(data.message.senderId===selectedUserId) {
            
            if(data?.message && data?.sender) {
                newMessage = {
                    date: new Date().toISOString().split('T')[0],
                    messages: [
                        {
                            message: data.message.content,
                            date: new Date().toISOString(),
                            is_sender: data.senderType === 1
                        }
                    ]
                };
            }
        
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const existingMessageIndex = updatedMessages.findIndex(
                    (msg) => msg?.date === newMessage?.date
                );

                if (existingMessageIndex !== -1) {
                    updatedMessages[existingMessageIndex].messages.push(...newMessage.messages);
                } else {
                    updatedMessages.push(newMessage);
                }
                return updatedMessages;
            });
            }

            if(selectedUserId === data.message.senderId){
                socket?.emit("readMessage", {
                  recipient_type: 1, 
                  recipient_id: Number(userid), 
                  sender_type: 0, 
                  sender_id: data.message.senderId, 
                });
              }
        
              //check if new user!
              if(users.filter((user)=>user.account_id===data.message.senderId).length===0) {
                socket?.on('chatList', (data: any) => {
                  setUsers(data);
                  setFilteredUsers(data);
                });
              }
                
                setUsers((currentUsers: any) => {
                  return currentUsers.map((user: any) => {
                    if (user.account_id === data.message.senderId) {
                      return {
                        ...user,
                        last_message: data.message.content,
                        last_message_date: new Date().toISOString(), 
                        unread_messages_count: selectedUserId === data.message.senderId ? 0: user.unread_messages_count + 1
                      };
                    }
                    return user;
                  });
                });
                
                setFilteredUsers((currentFilteredUsers: any) => {
                  return currentFilteredUsers.map((user: any) => {
                    if (user.account_id === data.message.senderId) {
                      return {
                        ...user,
                        last_message: data.message.content,
                        last_message_date: new Date().toISOString(),
                        unread_messages_count: selectedUserId === data.message.senderId ? 0: user.unread_messages_count + 1
                      };
                    }
                    return user;
                  });
                });
        
        };

        socket?.on("getMessage", getMessage);

        return () => {
            socket?.off("getMessage", getMessage);
        };
    }, [socket, selectedUserId]); // Include socket in the dependency array

    const handleUserClick = (userId: number, userName: string, userMessages: any[]) => {
        setSelectedUserId(userId);
        setSelectedUserName(userName);
        setMessages(userMessages);

        const updatedUsers = users.map((user) => {
            if (user.account_id === userId) {
                return {
                    ...user,
                    unread_messages_count: 0,
                };
            }
            return user;
        });
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
    };

    return (
        <>
            <Stack direction="row" sx={{ width: "100%" }}>
                {/* generalapp{newSocket.connected ? "true":} */}

                {/* Chats */}
                <Chats onUserClick={handleUserClick} onlineUsers={onlineUsers}
                    users={users}
                    setUsers={setUsers}
                    filteredUsers={filteredUsers}
                    setFilteredUsers={setFilteredUsers}
                    selectedUserId={selectedUserId}
                    setSelectedUserId={setSelectedUserId} 
                    />
                <Box sx={{
                    height: "100%", width: "calc(100vw - 420px)",
                    backgroundColor: "#fcfdfd",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                }}>

                    {
                        !selectedUserId ?
                            <Box height={"100%"} display="flex" alignItems="center" justifyContent="center" sx={{ backgoundColor: '#fcfdfd' }}>
                                Select a chat to start messaging
                            </Box> : < Conversation messages={messages} selectedUserName={selectedUserName} onlineUsers={onlineUsers} selectedUserId={selectedUserId} sendMessage={sendMessage} />
                    }
                </Box>
            </Stack >
        </>
    );
};

export default GeneralApp;
