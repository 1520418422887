import PropTypes from "prop-types";
import { ReactComponent as Logo } from "../../../../../assets/icon/logo.svg";
import "./index.style.less";
const AppLogo = () => {
  return (
    //TODO use logo
    <div className="app-title-logo">
      {/* <img src="/assets/images/logo.jpg" alt="Virtual Fairs logo" /> */}
      <Logo />
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
