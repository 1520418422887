import React from "react";
import { initialUrl } from "../shared/constants/AppConst";
import { authRouteConfig } from "./auth";
import GeneralApp from "./chat/GeneralApp";
import { errorPagesConfigs } from "./errorPages";
import Error403 from "./errorPages/Error403";
import Error404 from "./errorPages/Error404";
import "./index.less";
const MyProfile = React.lazy(() => import("./profile"));
const Images = React.lazy(() => import("./images"));
const Videos = React.lazy(() => import("./videos"));
const Programs = React.lazy(() => import("./programs"));
const StandDesign = React.lazy(() => import("./StandDesign"));
const Links = React.lazy(() => import("./links"));
const Files = React.lazy(() => import("./files"));
const Notifications = React.lazy(() => import("./notifications"));
const ConfirmCode = React.lazy(() => import("./auth/confirm-code"));
const authorizedStructure = {
  fallbackPath: "/sign-in",
  unAuthorizedComponent: <Error403 />,
  routes: [
    {
      path: "/my-profile",
      element: <MyProfile />,
    },
    {
      path: "/stand-design",
      element: <StandDesign />,
    },
    {
      path: "/images",
      element: <Images />,
    },

    {
      path: "/videos",
      element: <Videos />,
    },
    {
      path: "/programs",
      element: <Programs />,
    },
    {
      path: "/links",
      element: <Links />,
    },
    {
      path: "/files",
      element: <Files />,
    },
    {
      path: "/notifications",
      element: <Notifications />,
    },
    {
      path: "/chat",
      element: <GeneralApp />,
    }
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: "*",
      element: <Error404 />,
    },
    {
      path: "/confirm-code/:email",
      element: <ConfirmCode />,
    },
  ]),
};

export { anonymousStructure, authorizedStructure, unAuthorizedStructure };
