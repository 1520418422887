import { ILoginDto } from "./dtos/login-dto";
import ApiService from "../../utils/base-api/api-service";
import { ILoginResponse } from "./dtos/login-response";
import { IResetPassword } from "./dtos/reset-password";
import { IConfirmCode } from "./dtos/confirm-code";
import { IResendCode } from "./dtos/resend-code";
import {
  AdminDTO,
  IAdmin,
  ResponseUserDTO,
  UpdateExhibitorDTO,
} from "../admins/dtos";

class AuthService extends ApiService {
  constructor() {
    super({
      baseURL: `${process.env.REACT_APP_PUBLIC_BASE_API}`,
    });
  }

  login(data: ILoginDto): Promise<ILoginResponse> {
    return this.post("/login", data);
  }

  register(data: ILoginDto): Promise<ILoginResponse> {
    return this.post("/register", data);
  }
  logout() {
    return this.get("/logout");
  }
  profile(): Promise<ResponseUserDTO> {
    return this.get("/profile");
  }
  resetPassword(data: IResetPassword) {
    return this.patch("/reset_password", data);
  }
  confirmCode(data: IConfirmCode) {
    return this.post("/confirm_code", data);
  }

  resendCode(data: IResendCode) {
    return this.post("/resend_code", data);
  }
  updateProfile(data: any) {
    return this.post("/update_profile", data);
  }
}

export const authService = new AuthService();
