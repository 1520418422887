import { Button, Dropdown, List, Menu } from "antd";
import { useEffect, useState } from "react";

import { IoNotifications } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationAlert from "../../../NotificationAlert";
import { onMessageListener } from "../../../firebaseInit";
import { notificationsService } from "../../../shared/services/notifications";
import { NotificationDTO } from "../../../shared/services/notifications/dtos";
import IntlMessages from "../../utility/IntlMessages";
import AppScrollbar from "../AppScrollbar";
import NotificationItem from "./NotificationItem";
import "./index.style.less";

const AppNotifications = () => {
  const [data, setData] = useState<NotificationDTO[]>([]);
  const [count, setCount] = useState(0);
  const [isRefreshTable, setIsRefreshTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const notification = await notificationsService.getFirstTen();
        setData(notification?.data);
        if (path !== "/notifications") {
          const count = await notificationsService.unreadCount();
          setCount(count.data);
        } else setCount(0);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [isRefreshTable, isVisible, location]);

  const handleIsRead = async () => {
    try {
      if (count > 0) {
        const data = await notificationsService.isRead();
        if (data?.success) {
          setCount(0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotification(false);
    }, 100);

    return () => clearTimeout(timeout);
  }, [showNotification]);

  useEffect(() => {
    let timeoutId: any; // Declare timeoutId variable

    onMessageListener()
      .then((payload) => {
        setIsRefreshTable(!isRefreshTable);
        setNotificationMessage(payload.notification);
        setShowNotification(true);

        // Clear previous timeout
        if (timeoutId) clearTimeout(timeoutId);

        // Set new timeout
        timeoutId = setTimeout(() => {
          setShowNotification(false);
        }, 0);
      })
      .catch((error) =>
        console.error("An error occurred while retrieving message: ", error)
      );

    // Clean up function to clear timeout when component unmounts or when a new notification arrives
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isRefreshTable]);


  const menu = (
    <Menu className="notify-header-message">
      <Menu.Item className="header">
        <IntlMessages id="common.notifications" />
      </Menu.Item>
      <Menu.Item>
        <AppScrollbar className="notify-scroll-submenu">
          <List
            className="notify-list"
            dataSource={data}
            renderItem={(item) => {
              return <NotificationItem key={item.title} item={item} />;
            }}
          />
        </AppScrollbar>
      </Menu.Item>
      <Menu.Item>
        <Button
          type="primary"
          onClick={() => navigate("/notifications")}
          className="notify-btn-all"
        >
          <IntlMessages id="common.viewAll" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        onVisibleChange={(e) => e && handleIsRead()}
        overlay={menu}
        className="dropdown"
        trigger={["click"]}
      >
        <a className="notify-link" onClick={(e) => e.preventDefault()}>
          <span className="notify-icon">
            <IoNotifications style={{ color: '#2c3c6c' }} />

            {count > 0 && <span className="count">{count}</span>}
          </span>
          <span className="notify-text">
            <IntlMessages id="common.notifications" />
          </span>
        </a>
      </Dropdown>
      {showNotification && <NotificationAlert notification={notificationMessage} />}
    </>
  );
};

export default AppNotifications;
