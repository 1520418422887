import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const TextMsg = ({ el }: any) => {
    const theme = useTheme();
    return (
        <Stack direction="row" justifyContent={el?.is_sender ? "start" : "end"}>
            <Box p={1.5}
                sx={{
                    backgroundColor: el?.is_sender
                        ? theme.palette.background.default
                        : theme.palette.primary.main,
                    borderRadius: 1.5, //1.5 * 8 => 12px
                    width: "max-content"
                }}
            >
                <Typography variant="body2" style={{ color: el?.is_sender ? theme.palette.text.primary : "#fff" }}>
                    {el.message}
                </Typography>
            </Box>
        </Stack>
    )
}

const TimeLine = ({ el }: any) => {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Divider sx={{ width: "40%" }} />
            <Typography variant="caption" sx={{ color: theme.palette.text.primary }}>{el.text}</Typography>
            <Divider sx={{ width: "40%" }} />
        </Stack>
    )
}

export { TextMsg, TimeLine };
