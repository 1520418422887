import ApiService from "../../utils/base-api/api-service";
import { MessagesDTO, UsersChatListDTO } from "./dtos";

class ChatsService extends ApiService {
  constructor() {
    super({
      baseURL: `${process.env.REACT_APP_PUBLIC_BASE_API}/`,
    });
  }

  async getAll(): Promise<UsersChatListDTO[]> {
    return await this.get(`https://www.api.simatexfairs.com/api/get_chats`);
  }

  async getMessages(userId: number, accountType: string): Promise<MessagesDTO> {
    
    return this.get(`https://www.api.simatexfairs.com/api/chat?second_account_type=${accountType}&second_account_id=${userId}`);
  }

  async markAsRead(data: { sender_type: number | any, sender_id: number | any, recipient_type: number | any, recipient_id: number | any }) {
    try {
      const response: any = await this.post(`https://www.api.simatexfairs.com/api/chat/read`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error marking message as read');
    }
  }
}

export const chatsService = new ChatsService();
