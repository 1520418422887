import React, {ReactElement, ReactNode, useEffect} from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import AppLocale from '../../../shared/localization';
import {
  useLocaleActionsContext,
  useLocaleContext,
} from '../AppContextProvider/LocaleContextProvide';
import {IntlGlobalProvider} from '../helper/Utils';
import {useCookies} from 'react-cookie';
import {Default_Language} from '../../../shared/constants/AppConst';
import MainUtils from '../../../shared/utils/main';
import {useLayoutActionsContext} from '../AppContextProvider/LayoutContextProvider';
import {ThemeDirection} from '../../../shared/constants/AppEnums';

const AppLocaleProvider = ({children}: { children: ReactNode}) => {
  const {rtlLocale, locale}: any = useLocaleContext();
  const currentAppLocale: any = AppLocale[locale?.code];
  const [cookies] = useCookies([Default_Language]);
  const {updateLocale}: any = useLocaleActionsContext();
  const {updateDirection}: any = useLayoutActionsContext();

  useEffect(() => {
    if (!MainUtils.isEmptyValue(cookies[Default_Language])) {
      if (rtlLocale.indexOf(cookies[Default_Language]) !== -1) {
        updateDirection(ThemeDirection.RTL);
      } else {
        updateDirection(ThemeDirection.LTR);
      }
      updateLocale({code: cookies[Default_Language]});
    }
  }, [cookies]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <IntlGlobalProvider>{children}</IntlGlobalProvider>
    </IntlProvider>
  );
};

export default AppLocaleProvider;

AppLocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
