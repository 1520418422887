import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './shared/styles/vendors/index.css';
import AuthContextProvider from './pages/auth/context';


ReactDOM.render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>,
    document.getElementById('root')
);

reportWebVitals();
