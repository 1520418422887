import { Modal } from "antd";
import "./index.css";
import IntlMessages from "../../../domain/utility/IntlMessages";
import React, { Fragment, useState } from "react";
// import imag from "../../../assets/images/popups/icn_120_record.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ModalDTOS } from "./DTO";

const GlobalPopup = (props: ModalDTOS) => {
  const { lg, md } = useBreakpoint();
  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    setLoading(true);
    await props.handleSave();
    setLoading(false);
  };
  return (
    <Fragment>
      <Modal
        className="AddDialog"
        centered
        okButtonProps={{ loading: loading }}
        visible={props.visible}
        onOk={onOk}
        onCancel={() => props.setVisible(false)}
        okText={<IntlMessages id="common.yes" />}
        cancelText={<IntlMessages id="common.no" />}
        width={lg ? "30%" : md ? "70%" : "90%"}
      >
        {/* <img src={imag} /> */}
        <p className="title">{props.title}</p>
      </Modal>
    </Fragment>
  );
};

export default GlobalPopup;
