import "./shared/styles/crema.less";
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from "./domain";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "./pages/auth/context";
import { QueryClient, QueryClientProvider } from "react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useCookies } from "react-cookie";
import { AUTH_USERID } from "./shared/constants/AppConst";
const queryClient = new QueryClient();
export const SocketContext = createContext<Socket | null>(null);
export const OnlineUsersContext = createContext<number[]>([]);

const App = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<number[]>([]);
  const [cookies] = useCookies([AUTH_USERID]);

  useEffect(() => {
    const newSocket = io("https://studyinrussiaapp.tech:3001");
    const userid = cookies[AUTH_USERID];
 
    if (userid) {
      newSocket.emit('addNewAccount', { "id": Number(userid), "type": 1 });
      // No need to directly call onlineUsers or getChats here if the server automatically handles this upon addNewAccount
    }
    setSocket(newSocket);

    const updateOnlineUsers = (userIds: number[]) => {
      setOnlineUsers(userIds);
  };

  newSocket?.on("onlineUsers", updateOnlineUsers);
    
    return () => {
      socket?.off("onlineUsers", updateOnlineUsers);
      newSocket.disconnect();
    };
  }, [cookies]);

  return (
    <SocketContext.Provider value={socket}>
      <OnlineUsersContext.Provider value={onlineUsers}>
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <AppThemeProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <AuthRoutes>
                  <AppLayout />
                </AuthRoutes>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppThemeProvider>
        </QueryClientProvider>
      </AppContextProvider>
      </OnlineUsersContext.Provider>
    </SocketContext.Provider>
  );
};

export default App;
