import { Avatar, Badge, Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";

const truncateText = (string: string | any[], n: number) => {
    return string?.length > n ? `${string?.slice(0, n)}...` : string;
};

const StyledChatBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const formatDate = (dateTime: string) => {
    const date = moment(dateTime);
    return date.format('DD/MM/YYYY');
};

const Chat = ({ account_id,
    last_message,
    last_message_date,
    unread_messages_count,
    name, img, onClick, onlineUsers }: any) => {


    const isOnline = onlineUsers.includes(account_id);

    return (
        <StyledChatBox
            onClick={onClick}
            sx={{
                width: "100%",
                borderRadius: "8px", // Added borderRadius
                "&:hover": {
                    backgroundColor: "#EAEAEA", // Added hover background color
                },
            }}
            p={2}
            key={account_id}
        >
            <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={2}>
                    {" "}
                    {isOnline ? (
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            variant="dot"
                        >
                            <Avatar alt={name} src={img} sx={{ width: 40, height: 40 }} />
                        </StyledBadge>
                    ) : (
                        <Avatar alt={name} src={img} sx={{ width: 40, height: 40 }} />
                    )}
                    <Stack spacing={0.3}>
                        <Typography variant="subtitle2"
                            sx={{ fontFamily: 'Montserrat, sans-serif' }}
                        >{name}</Typography>
                        <Typography variant="caption" sx={{ fontFamily: 'Montserrat, sans-serif' }}>{truncateText(last_message, 20)}</Typography>
                    </Stack>
                </Stack>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography sx={{ fontWeight: 600, color: "#909090", fontFamily: 'Montserrat, sans-serif' }} variant="caption">
                        {formatDate(last_message_date)}
                    </Typography>
                    <Badge
                        className="unread-count"
                        color="primary"
                        badgeContent={unread_messages_count}
                    />
                </Stack>
            </Stack>
        </StyledChatBox>
    );
};

export default Chat;
