import { Col, Dropdown, Layout, Menu, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { FiMoreVertical } from 'react-icons/fi';
import AppChat from '../../AppChat';
import AppNotifications from '../../AppNotifications';
import UserInfo from '../components/UserInfo';
import Breadcrumb from './breadcrumb';
import './index.style.less';
const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  const { Header } = Layout;

  const menuMobile = (
    <Menu>
      {/* <AppHeaderMessages /> */}
      {/* <AppNotifications /> */}
      {/* <AppLanguageSwitcher /> */}
    </Menu>
  );

  return (
    <Header className='app-header-mini-sidebar'>
      <Row justify={'space-between'} align={'middle'} style={{ width: '100%' }}>
        <Col>
          <Row align={'middle'}>
            {React.createElement(
              isCollapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
              {
                className: 'trigger',
                onClick: onToggleSidebar,
              },
            )}
            <Breadcrumb />
          </Row>
        </Col>
        <Col>
          <div className='app-header-mini-sidebar-sectionDesktop'>
            {/* <AppLanguageSwitcher /> */}
            <AppChat />
            <AppNotifications />
            {/* <AppHeaderMessages /> */}
            <UserInfo />
          </div>
          <div className='app-header-mini-sidebar-section-mobile'>
            <Dropdown overlay={menuMobile} trigger={['click']}>
              <a
                className='ant-dropdown-link'
                onClick={(e) => e.preventDefault()}>
                <FiMoreVertical />
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
};
