import {
  Box,
  Stack
} from "@mui/material";
import {
  MagnifyingGlass,
} from "phosphor-react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/Search/index";
import { chatsService } from "../../shared/services/chats";
import Chat from "./chat";
// import { newSocket } from "../../App";
import { useCookies } from "react-cookie";
import { SocketContext } from "../../App";
import { AUTH_USERID } from "../../shared/constants/AppConst";
import { AuthContext } from "../auth/context";
// import SimpleBar from 'simplebar-react';
// import 'simplebar/dist/simplebar.min.css';

const Chats = ({ onUserClick, onlineUsers, users, setUsers, filteredUsers, setFilteredUsers, selectedUserId, setSelectedUserId }:
  {
    onUserClick: (userId: number, userName: string, userMessages: any[]) => void, onlineUsers: any[], users: any[],
    setUsers: any, filteredUsers: any[], setFilteredUsers: any, setSelectedUserId: any, selectedUserId: any
  }) => {
  const authContext = useContext(AuthContext)
  const socket = useContext(SocketContext);
  const [selectedUserName, setSelectedUserName] = useState<string | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const firstUnreadMessageRef = useRef<HTMLDivElement | null>(null);
  const [message, setMessage] = useState("");
  const [cookies] = useCookies([AUTH_USERID]);
  const userid = cookies[AUTH_USERID]

  useEffect(() => {
    socket?.on('chatList', (data: any) => {
      setUsers(data);
      setFilteredUsers(data);
    });

    socket?.on("getMessage", (data: any) => {
      if (users.filter((user) => user.account_id === data.message.senderId).length === 0) {
        socket?.on('chatList', (data: any) => {
          setUsers(data);
          setFilteredUsers(data);
        });
      }
    });
  }, [socket]);

  const handleUserClick = (userId: number, userName: string) => {
    console.log('userId:', userId)
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    const accountType = '0';

    chatsService.getMessages(userId, accountType).then((data) => {
      console.log('data 8: ', data)
      if (data.success) {
        onUserClick(userId, userName, data.data);
        setMessages(data.data);
        socket?.emit("readMessage", {
          recipient_type: 1,
          recipient_id: Number(userid),
          sender_type: 0,
          sender_id: userId,
        });

        const updatedUsers = users.map((user) => {
          if (user.account_id === userId) {
            return {
              ...user,
              unread_messages_count: 0,
            };
          }
          return user;
        });

        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
      } else {
        console.error("Failed to fetch messages");
      }
    })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const filtered = users.filter((user) => {
      return user.name.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredUsers(filtered);
  };

  const sortedUsers = filteredUsers.slice().sort((a, b) => {
    return new Date(b.last_message_date).getTime() - new Date(a.last_message_date).getTime();
  });

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: 600,
          backgroundColor: "#ffffff",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
      >
        <Stack px={1} py={2} spacing={2} sx={{ maxHeight: "100vh" }}>
          <Stack sx={{ width: "100%" }}>
            <Search sx={{ backgroundColor: "#f0f0f0", borderRadius: "16px" }}>
              <SearchIconWrapper sx={{ color: "#2c3c6c", fontSize: "1.5rem" }}>
                <MagnifyingGlass color="#979aa0" size={24} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search..."
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                sx={{ fontSize: "1rem", fontFamily: 'Montserrat, sans-serif' }}
              />
            </Search>
          </Stack>

          <Stack sx={{ flexGrow: 1, height: "100%" }}>
            <Stack spacing={2.4}>
              {sortedUsers.map((el, idx) => {
                return <Chat {...el} key={el?.id} onlineUsers={onlineUsers} onClick={() => handleUserClick(el?.account_id, el?.name)} />;
              })}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Chats;
